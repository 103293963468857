import axios from 'axios'

// initial state
const state = {
    produits: []
  }
  
  // getters
  const getters = {
  }
  
  // actions
  const actions = {
  }
  
  // mutations
  const mutations = {

    async load_from_json(state){
      var produits_json = await axios.get('./data/produits_tries.json').then(res => res.data)
      state.produits = produits_json.produitstries
    },

  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }