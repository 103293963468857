<template>
  <v-container 
  class="text-center"
  fluid>
    <div
    class="text-uppercase"
    id="thetitle-name"
    :style="title_size_text">{{ $t("thedescription.title") }}
    </div>
    <h4 
    class="font-weight-thin"
    id="thetitle-baseline"
    :style="baseline_size_text">{{ baseline }}
    </h4>
  </v-container>
</template>

<script>
import config from '/config/Config.js';
export default {
    name: 'TheTitle',
    data: () => ({
      baseline: config.development.lemarche_website.baseline[1]
    }),

    computed: {
      title_size_text: function(){
        return this.$store.state.parameters.text_sizes.image_accueil.title[this.$vuetify.breakpoint.name]
      },
      baseline_size_text: function(){
        return this.$store.state.parameters.text_sizes.image_accueil.baseline[this.$vuetify.breakpoint.name]
      }
    }

}
</script>

<style scoped>
@import "../scss/thetitle.scss";
</style>