<template>
  <v-container id="thepicturebanner" fluid>
    <div class="thepicturebanner-image" v-for="image in images_displayed" :key="image">
      <div
        class="thepicturebanner-image-container"
        :style="[
          {
            backgroundImage:
              'url(' + require(`@/assets/marches/banner/${image}`) + ')',
          }
        ]"
      ></div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "ThePicturesBanner",

  data: () => ({
    images: ["6.jpg", "4.jpg", "2.jpg", "5.jpg", "10.jpg"],
  }),
  computed: {
    images_displayed: function () {
      if (this.$vuetify.breakpoint.name == "xs") {
        return [this.images[1], this.images[2], this.images[3]];
      }
      return this.images;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/thepicturebanner.scss";
</style>
