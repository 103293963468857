<template>
  <v-app>
    <v-app-bar
      app
      id="app-bar"
      :elevation="elevation_bar"
      :style="{ background: 'rgba(255, 255, 255,' + opacity_bar + ')' }"
    >
      <template>
        <TheMenu
          v-bind:scrollPosition="scrollPosition"
          v-bind:innerheight="innerheight"
        />
      </template>
      <v-btn icon @click="drawer = !drawer" v-if="screen_size == 'xs'">
        <v-icon>fas fa-bars</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main app style="padding: 0" class="content">
      <TheNavBar v-if="screen_size == 'xs'" v-bind:drawer="drawer" />
      <v-container fluid fill-height id="app-container">
        <router-view />
      </v-container>
    </v-main>
    <v-footer app padless absolute v-if="!is_home_page">
      <!-- <TheBottomBar /> -->
    </v-footer>
  </v-app>
</template>

<script>
import Constants from "./constants";
// import TheBottomBar from "./components/TheBottomBar.vue";
import TheMenu from "./components/TheMenu.vue";
import TheNavBar from "./components/TheNavBar.vue";

export default {
  components: {
    // TheBottomBar,
    TheMenu,
    TheNavBar,
  },
  data: () => ({
    innerheight: null,
    scrollPosition: 0,
    drawer: false,
  }),
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
    this.innerheight = window.innerHeight;
    this.onResize();
  },

  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    onResize: function () {
      this.innerheight = window.innerHeight;
    },
  },
  computed: {
    is_home_page: function () {
      return this.$route.name == Constants.ROUTES.HOME;
    },
    elevation_bar: function () {
      if (this.is_home_page) {
        let elev = Math.floor((this.scrollPosition / this.innerheight) * 5);
        return elev > 5 ? 5 : elev;
      } else {
        return 5;
      }
    },
    hide_footer: function () {
      return this.$store.state.parameters.hide_footer;
    },
    opacity_bar: function () {
      if (this.is_home_page) {
        let op = this.scrollPosition / this.innerheight;
        return op > 1 ? 1 : op;
      } else {
        return 1;
      }
    },
    screen_size: function () {
      return this.$vuetify.breakpoint.name;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./scss/app.scss";
</style>
