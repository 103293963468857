import Vue from 'vue'
import axios from 'axios'
// "id"
// "name"
// "description"
// "address"
// "address_city"
// "address_postalcode"

// initial state
const state = {
    lieux: []
  }
  
  // getters
  const getters = {
  }
  
  // actions
  const actions = {
  }
  
  // mutations
  const mutations = {

    async load_from_json(state){
      var lieux_json = await axios.get('./data/lieux.json').then(res => res.data)
      state.lieux = lieux_json.lieux
    },

    store_lieux_v8 (state, lieux) {
      state.lieux = []

      for (var j = 0; j < lieux.length; j ++){
        state.lieux.push({
          id : lieux[j]['id'],
          name : lieux[j]['name'],
          description : lieux[j]['description'],
          address : lieux[j]['address'],
          address_city : lieux[j]['address_city'],
          address_postalcode : lieux[j]['address_postalcode']
        })
      }
      Vue.$log.debug(state.lieux)
    },
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }