import Vue from 'vue'
import axios from 'axios'

// initial state
const state = {
    evenements: [],
    images: {
      "1": "MA1-2-3-4-5.jpg",
      "2": "MA1-2-3-4-5.jpg",
      "3": "MA1-2-3-4-5.jpg",
      "4": "MA1-2-3-4-5.jpg",
      "5": "MA1-2-3-4-5.jpg",
      "6": "MA6-7-8.png",
      "7": "MA6-7-8.png",
      "8": "MA6-7-8.png",
      "9": "visuel1.jpg",
      "10": "visuel2.jpg"
    },
    other_image: "docks.jpg"
  }
  
  // getters
  const getters = {
    get_future_events: state => {
      var tps_events = [];
      var now = new Date();
      for (const event of state.evenements){
        var end_date = Date.parse(event.date_end);
        
        if (now.getTime() <= end_date){
            tps_events.push(event);
        }
      }
      return tps_events
    }, 
    get_events_decrease_order: state => {
      var tps_events = state.evenements;
      tps_events.sort(function(a,b){
        return new Date(b.date_end) - new Date(a.date_end);
      });
      return tps_events
    }
  }
  
  // actions
  const actions = {
  }
  
  // mutations
  const mutations = {

    async load_from_json(state){
      var evenements_json = await axios.get('./data/evenements.json').then(res => res.data)
      state.evenements = evenements_json.evenements
      // state.evenements = evenements_json.evenements.slice(0, evenements_json.evenements.length -1) // A ENLEVER POUR AFFICHER LE DERNIER MARCHE
    },

    store_evenements (state, events) {
        state.evenements = events
        var arrayLength = events.length;
        for (var i = 0; i < arrayLength; i++) {
            state.evenements[i]["number"] = state.evenements[i]["name"].split('#')[1]
            state.evenements[i]["name"] = state.evenements[i]["name"].split('#')[0]
            //state.evenements[i]["name"] = state.evenements[i]["name"].substring(0, state.evenements[i]["name"].length - 2)
            state.evenements[i]["image"] = state.other_image
            for (var prop in state.images){
              if(String(state.evenements[i].number) == prop){
                state.evenements[i]["image"] = state.images[prop]
                break;
              }
            }
        }
    },

    store_evenements_v8 (state, events) {
      state.evenements = []

      for (var j = 0; j < events.length; j ++){
        var image = ''
        if (events[j]['name'].split('#')[1] in state.images){
          image = state.images[events[j]['name'].split('#')[1]]
        } else {
          image = state.other_image
        }

        state.evenements.push({
          id : events[j]['id'],
          name : events[j]['name'].split('#')[0].trim(),
          description : events[j]['description'].replace('&#039;', "'"),
          description_longue : events[j]['description_2_c'].replace('&#039;', "'"),
          duration : events[j]['duration'],
          date_start : events[j]['date_start'],
          date_end : events[j]['date_end'],
          location : events[j]['fp_event_locations_fp_events_1_name'],
          location_id : events[j]['fp_event_locations_fp_events_1fp_event_locations_ida'],
          number : events[j]['name'].split('#')[1],
          image : image,
          list_enseignes : [],
        })
      }
      Vue.$log.debug(state.evenements)
    },

    add_lieux_v8 (state, lieux) {
      for (var i = 0; i < state.evenements.length; i++){
        for (var j = 0; j < lieux.lieux.length; j ++){
          if (lieux.lieux[j].id == state.evenements[i].location_id){
            state.evenements[i]["location_description"] = lieux.lieux[j].description
            break
          }
        }
      }
      Vue.$log.debug(state.evenements) 
    }
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }