<template>
  <v-container fill-height fluid id="homeview">
    <TheDescription />
    <v-container id="homeview-markets" fluid>
      <go-top :size="50" :bottom="80" bg-color="var(--v-custom_yellow-base)">
      </go-top>
      <ThePartner/>
      <TheVideo/>
      <TheFavoriteActors/>
      <TheHalls/>
      <ThePartnerLogos/>
      <ThePicturesBanner/>
      <TheBottomBar />
    </v-container>
  </v-container>
</template>

<script>
import TheDescription from "../components/TheDescription.vue";
import TheBottomBar from "../components/TheBottomBar.vue";
import ThePartner from "../components/ThePartner.vue"
import GoTop from "@inotom/vue-go-top";
import TheVideo from "../components/TheVideo.vue";
import TheFavoriteActors from "../components/TheFavoriteActors.vue";
import TheHalls from "../components/TheHalls.vue";
import ThePartnerLogos from "../components/ThePartnerLogos.vue"
import ThePicturesBanner from "../components/ThePicturesBanner.vue"

export default {
  name: "HomeView",
  components: {
    GoTop,
    TheDescription,
    TheBottomBar,
    ThePartner,
    TheVideo,
    TheFavoriteActors,
    TheHalls,
    ThePartnerLogos,
    ThePicturesBanner
  },

  data: () => ({
    video: "/assets/video.mp4",
  })
};
</script>

<style scoped>
@import "../scss/homeview.scss";
</style>
