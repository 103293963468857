import Vue from "vue";
import Router from "vue-router";
import Home from "./views/HomeView.vue";
import Constants from "./constants";
import BackApi from "./functions/BackApi";

Vue.use(Router);
const router = new Router({
  base: process.env.VUE_APP_PUBLICPATH,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: "/",
      name: Constants.ROUTES.HOME,
      component: Home,
    },
    {
      path: "/enseignes",
      name: "enseignes",
      component: () => import("./views/ActorsView.vue"),
      props: true,
    },
    {
      path: "/enseignes/:id",
      name: "blabla",
      component: () => import("./views/AActorView.vue"),
      meta: { actor: null },
    },
    {
      path: "/evenements",
      name: "evenements",
      component: () => import("./views/EventsView.vue"),
    },
    {
      path: "/contact",
      name: "contact",
      component: () => import("./views/ContactsView.vue"),
    },
    {
      path: "/exposer",
      name: "exposer",
      component: () => import("./views/ExposeView.vue"),
    },
    {
      path: "/mentions-legales",
      name: "mentions-legales",
      component: () => import("./views/LegalInformationView.vue"),
    },
  ],
});

router.beforeResolve(async (to, from, next) => {
  if (to.name === "blabla") {
    await BackApi.getActorById(to.params.id).then((response) => {
      to.meta.actor = response;
    });
    next();
  } else {
    next();
  }
});

export default router;
