<template>
  <v-container id="thepartnerlogos" fluid>
    <v-row justify="center" align="center">
      <v-col
        v-for="logo in paternaires_logos"
        :key="logo.img"
        :cols="logo.col"
        :sm="logo.sm"
        :lg="logo.lg"
      >
        <v-img
          :width="logo.size"
          v-bind:src="require(`../assets/marches/partenaires/${logo.img}`)"
          contain
        >
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { development } from "../..//config/Config";

export default {
  name: "ThePartnerLogos",

  components: {},

  data: () => ({
    paternaires_logos: development.lemarche_website.paternaires_logos,
  }),
};
</script>

<style scoped>
@import "../scss/thepartnerlogos.scss";
</style>
