import { development } from "../../config/Config";
import axios from "axios";

function prepareMultiParams(multiParams, paramName) {
  var allParams = "";
  if (multiParams != null && multiParams.length > 0) {
    allParams = `${multiParams
      .map((n, index) => {
        if (index == 0) {
          return `${n}`;
        }
        return paramName + `=${n}`;
      })
      .join("&")}`;
  }
  return allParams;
}

function getActors(
  page,
  size,
  halles,
  hasPhoto,
  isPromoted,
  hasManagement,
  managementTitle,
  sortBy,
  actorName,
  events,
  eventName
) {
  return new Promise((resolve, reject) => {
    try {
      var url = process.env.VUE_APP_BACK + development.backapi.actors;

      var params = {
        page: page,
        size: size,
      };

      if (halles != null) {
        params.halles = prepareMultiParams(halles, "halles");
      }
      if (hasPhoto != null) {
        params.hasPhoto = hasPhoto;
      }
      if (isPromoted != null) {
        params.isPromoted = isPromoted;
      }
      if (hasManagement != null) {
        params.hasManagement = hasManagement;
      }
      if (managementTitle != null) {
        params.managementTitle = managementTitle;
      }

      if (sortBy != null) {
        params.sortBy = prepareMultiParams(sortBy, "sortBy");
      }
      if (actorName != null) {
        params.actorName = actorName;
      }
      if (events != null) {
        params.events = prepareMultiParams(events, "events");
      }
      if (eventName != null) {
        params.eventType = eventName;
      }

      axios
        .get(url, {
          params,
          paramsSerializer: (params) => {
            var result = "";
            for (var name in params) {
              var value = params[name];
              if (result == "") {
                result = name + "=" + value;
              } else {
                result = result + "&" + name + "=" + value;
              }
            }

            result = result.replaceAll(":", "%3A");
            return result;
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
}

async function getActorById(id) {
  return new Promise((resolve, reject) => {
    try {
      let url =
        process.env.VUE_APP_BACK + development.backapi.actors + "/" + id;

      axios
        .get(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
}

function getManagements(page, size, toDisplay) {
  return new Promise((resolve, reject) => {
    try {
      var url = process.env.VUE_APP_BACK + development.backapi.managements;
      var params = {
        page: page,
        size: size,
      };

      if (toDisplay != null) {
        params.toDisplay = toDisplay;
      }

      axios
        .get(url, {
          params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
}

function getEvents(page, size, sortBy) {
  return new Promise((resolve, reject) => {
    try {
      var url = process.env.VUE_APP_BACK + development.backapi.events;
      var params = {
        page: page,
        size: size,
      };

      if (sortBy != null) {
        params.sortBy = prepareMultiParams(sortBy, "sortBy");
      }

      axios
        .get(url, {
          params,
          paramsSerializer: (params) => {
            var result = "";
            for (var name in params) {
              var value = params[name];
              if (result == "") {
                result = name + "=" + value;
              } else {
                result = result + "&" + name + "=" + value;
              }
            }

            result = result.replaceAll(":", "%3A");
            return result;
          },
        })
        .then((res) => {
          resolve(res.data.elements);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
}

function getMembershipForm(page, size, sortBy) {
  return new Promise((resolve, reject) => {
    try {
      var url = process.env.VUE_APP_BACK + development.backapi.membershipForm;
      var params = {
        page: page,
        size: size,
      };

      if (sortBy != null) {
        params.sortBy = prepareMultiParams(sortBy, "sortBy");
      }

      axios
        .get(url, {
          params,
          paramsSerializer: (params) => {
            var result = "";
            for (var name in params) {
              var value = params[name];
              if (result == "") {
                result = name + "=" + value;
              } else {
                result = result + "&" + name + "=" + value;
              }
            }

            result = result.replaceAll(":", "%3A");
            return result;
          },
        })
        .then((res) => {
          resolve(res.data.elements);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
}

function getMembershipFormFile(formId) {
  return new Promise((resolve, reject) => {
    try {
      const url =
        process.env.VUE_APP_BACK +
        development.backapi.membershipForm +
        "/" +
        formId;

      axios({
        url: url,
        method: "GET",
        responseType: "blob",
      })
        .then((res) => {
          resolve(URL.createObjectURL(res.data));
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
}

function getNextEvent() {
  return new Promise((resolve, reject) => {
    try {
      var url = process.env.VUE_APP_BACK + development.backapi.events + "/next";

      axios
        .get(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
}

function getEventsToDisplay() {
  return new Promise((resolve, reject) => {
    try {
      var url =
        process.env.VUE_APP_BACK + development.backapi.events + "/display";

      axios
        .get(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
}

function getProducts(page, size, sortBy, actorId) {
  return new Promise((resolve, reject) => {
    try {
      var url = process.env.VUE_APP_BACK + development.backapi.products;
      var params = {
        page: page,
        size: size,
        actorId: actorId,
      };

      if (sortBy != null) {
        params.sortBy = prepareMultiParams(sortBy, "sortBy");
      }

      axios
        .get(url, {
          params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
}

function getUsers(page, size, sortBy) {
  return new Promise((resolve, reject) => {
    try {
      var url = process.env.VUE_APP_BACK + development.backapi.users;
      var params = {
        page: page,
        size: size,
      };

      if (sortBy != null) {
        params.sortBy = prepareMultiParams(sortBy, "sortBy");
      }

      axios
        .get(url, {
          params,
          paramsSerializer: (params) => {
            var result = "";
            for (var name in params) {
              var value = params[name];
              if (result == "") {
                result = name + "=" + value;
              } else {
                result = result + "&" + name + "=" + value;
              }
            }

            result = result.replaceAll(":", "%3A");
            return result;
          },
        })
        .then((res) => {
          resolve(res.data.elements);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
}

export default {
  getActors,
  getManagements,
  getEvents,
  getUsers,
  getProducts,
  getNextEvent,
  getEventsToDisplay,
  getMembershipForm,
  getMembershipFormFile,
  getActorById,
};
