export default {
    urlify: function(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    text = text.replace(urlRegex, function(url) {
        return '<a href="' + url + '" target="_blank">' + url + '</a>';
    })
    const urlRegexWww = /( |^)www\..*\.([a-z]){1,3}/g;
    text =  text.replace(urlRegexWww, function(url) {
        return '<a href="' + 'http://' + url.trim() + '" target="_blank">' + url + '</a>';
    })
    const mailRegex = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g;
    text =  text.replace(mailRegex, function(mail) {
        return '<a href="mailto:' + mail + '?">' + mail + '</a>';
    })
    return text;
  }
}