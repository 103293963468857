<template>
  <v-slide-item v-slot:default="{ active, toggle }">
    <v-container fluid class="afavoriteactor">
      <h4 class="font-weight-medium afavoriteactor-title">
        {{ actor.name }}
      </h4>
      <transition name="expand" :appear="true">
        <v-card :width="card_size" :style="!has_text ? {pointerEvents: 'none'} : ''">
          <v-img
            :aspect-ratio="screen_size == 'xs' ? 1 : 1.8"
            @click="toggle"
            :src="img_url(actor)"
            style="cursor: pointer"
          />
          <v-expand-transition duration="10000">
            <div v-if="model != null && active">
              <v-divider></v-divider>

              <v-card-text
                class="afavoriteactor-text"
                v-html="urlify(actor.promotionText)"
                target="_blank"
              >
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </transition>
    </v-container>
  </v-slide-item>
</template>

<script>
import methods from "../functions/methods";

export default {
  name: "AFavoriteActor",

  props: {
    actor: Object,
    model: Number,
  },
  computed: {
    has_text: function(){
      return this.actor.promotionText;
    },
    screen_size: function () {
      return this.$vuetify.breakpoint.name;
    },
    card_size: function () {
      let temp;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          temp = "70vw";
          break;
        case "sm":
          temp = "30vw";
          break;
        case "md":
          temp = "25vw";
          break;
        case "lg":
          temp = "20vw";
          break;
        default:
          temp = "20vw";
          break;
      }
      return temp;
    }
  },
  methods: {
    on_click_actor(){
      return true
    },
    img_url(enseigne_courante) {
      return process.env.VUE_APP_IMAGES + enseigne_courante.id + "_photo";
    },
    urlify(text) {
      return methods.urlify(text);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/afavoriteactor.scss";
</style>
