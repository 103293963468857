<template>
    <v-card
    @click="onclick"
    :elevation="hover ? 12 : 2"
    >
      <v-img  class="d-flex align-center"
        v-bind:src=" require( `../assets/marches/logos_2/${halle.img}` ) "
        aspect-ratio=1.5
      >
            <h4 class="font-weight-thin ahallitem-card-title"> {{ halle.name }}</h4>
        </v-img>
    </v-card>
</template>

<script>
export default {
  name: 'AHallItem',
  props: {
    halle: Object,
    hover: Boolean,
  },
  methods: {
    onclick: function(){
      this.$router.push({ name: 'enseignes', params: { search: this.halle.name }})
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/ahallitem.scss";
</style>
