<template>
  <v-container
    id="thepartner"
    fluid
  >
    <h1 class="app-sub-title">
      {{ $t("thepartner.title") }}
    </h1>
    <v-divider class="app-sub-title-divider" />
    <v-hover v-slot:default="{ hover }">
      <v-carousel
        :cycle="!hover"
        interval="3000"
        hide-delimiter-background
        :hide-delimiters="!hover"
        height="100%"
        :show-arrows="false"
      >
        <v-carousel-item
          v-for="(partenaire, i) in actors"
          :key="i"
          eager
        >
          <v-img
            :aspect-ratio="screen_size == 'xs' ? 1.5 : 3.2"
            :src="img_url(partenaire)"
          >
            <v-card-title v-if="!hover" class="thepartner-card-title">{{
              partenaire.name
            }}</v-card-title>
            <v-expand-transition>
              <div
                v-if="hover"
                class="thepartner-card-expand d-flex transition-fast-in-fast-out v-card--reveal"
              >
                <div
                  class="thepartner-card-expand-text"
                  v-html="urlify(partenaire.promotionText)"
                ></div>
              </div>
            </v-expand-transition>
          </v-img>
        </v-carousel-item>
      </v-carousel>
    </v-hover>
  </v-container>
</template>

<script>
import methods from "../functions/methods";
import BackApi from "../functions/BackApi";

export default {
  name: "ThePartner",

  components: {},

  data: () => ({
    model: null,
    halle_display: "Partenaires",
    actors: null,
  }),

  mounted: function () {
    BackApi.getActors(
      0,
      100,
      ["partenaire"],
      true,
      true,
      null,
      null,
      ["name:asc"],
      null,
      null
    ).then((response) => (this.actors = response.elements));
  },
  computed: {
    screen_size: function () {
      return this.$vuetify.breakpoint.name;
    },
    tocycle: function () {
      return this.hover;
    },
  },
  methods: {
    img_url(enseigne_courante) {
      return process.env.VUE_APP_IMAGES + enseigne_courante.id + "_photo";
    },
    urlify(text) {
      if (text != null) {
        return methods.urlify(text);
      }
      return text;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/thepartner.scss";
</style>
