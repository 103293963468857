<template>
  <v-container fluid id="thefavoriteactors">
    <div v-for="item in managements" :key="item.id">
      <h1 class="app-sub-title">
        {{ item.name }}
      </h1>
      <AFavoriteGroupActors :management="item"/>
    </div>
  </v-container>
</template>

<script>
import BackApi from "../functions/BackApi";
import AFavoriteGroupActors from "./AFavoriteGroupActors.vue";

export default {
  name: "TheFavoriteActors",

  components: {
    AFavoriteGroupActors,
  },

  data: () => ({
    managements: null,
  }),

  mounted: function () {
    BackApi.getManagements(0, 100, true).then(
      (response) => (this.managements = response.elements)
    );
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/thefavoriteactors.scss";
</style>
