<template>
  <v-container fluid id="afavoritegroupactors">
    <v-row justify="center">
      <v-slide-group
        v-model="model"
        class="afavoritegroupactors-slide-group"
        center-active
      >
        <AFavoriteActor
          v-for="actor in actors"
          :key="actor.name"
          :actor="actor"
          :model="model"
        />
      </v-slide-group>
    </v-row>
  </v-container>
</template>

<script>
import BackApi from "../functions/BackApi";
import AFavoriteActor from "./AFavoriteActor.vue";

export default {
  name: "AFavoriteGroupActors",

  props: {
    management: Object,
  },
  components: { AFavoriteActor },

  data: () => ({
    model: null,
    actors: null,
  }),

  mounted: function () {
    BackApi.getActors(
      0,
      100,
      null,
      null,
      true,
      true,
      this.management.name,
      ["name:asc"],
      null,
      null
    ).then((response) => (this.actors = response.elements));
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/afavoritegroupactors.scss";
</style>
