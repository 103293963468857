
// initial state
const state = {
    home_window: 0,
    hide_footer: true,
    size: '',
    btn_size: {
      'xs':'x-small',
      'sm':'small',
      'md':'small',
      'lg':'small',
      'xl':'large'},
    text_sizes: {
      image_accueil: {
        title: {
          'xs': 'font-size: 1.5em', 
          'sm': 'font-size: 2.3em', 
          'md': 'font-size: 2.4em', 
          'lg': 'font-size: 2.7em', 
          'xl': 'font-size: 3.5em',
        },
        baseline: {
          'xs': 'font-size: .9em', 
          'sm': 'font-size: 1.em', 
          'md': 'font-size: 1.2em', 
          'lg': 'font-size: 1.5em', 
          'xl': 'font-size: 2.3em',
        },
      },
      menu: {
        'xs': 'font-size: .7em', 
        'sm': 'font-size: .85em', 
        'md': 'font-size: 1.em', 
        'lg': 'font-size: 1.0em', 
        'xl': 'font-size: 1.2em',
      },
      btn: {
        'xs': 'font-size: .55em', 
        'sm': 'font-size: .8em', 
        'md': 'font-size: .9em', 
        'lg': 'font-size: 1em', 
        'xl': 'font-size: 1em',
      },
      news: {
        paragraph: {
          'xs': 'font-size: .8em', 
          'sm': 'font-size: .9em', 
          'md': 'font-size: 1.em', 
          'lg': 'font-size: 1.1em', 
          'xl': 'font-size: 1.2em',
        },
        title: {
          'xs': 'font-size: 1.1em', 
          'sm': 'font-size: 1.3em', 
          'md': 'font-size: 1.4em', 
          'lg': 'font-size: 1.7em', 
          'xl': 'font-size: 1.9em',
        },
      },
      global: {
        title: {
          'xs': 'font-size: 1.7em', 
          'sm': 'font-size: 2.1em', 
          'md': 'font-size: 2.4em', 
          'lg': 'font-size: 1.7em', 
          'xl': 'font-size: 1.9em',
        },
      },
      halles: {
        title: {
          'xs': 'font-size: 1.3em', 
          'sm': 'font-size: 1.3em', 
          'md': 'font-size: 1.6em', 
          'lg': 'font-size: 2.4em', 
          'xl': 'font-size: 2.2em',
        },
      },
      evenements: {
        info: {
          'xs': 'font-size: .6em', 
          'sm': 'font-size: .75em', 
          'md': 'font-size: .8em', 
          'lg': 'font-size: .9em', 
          'xl': 'font-size: 1.0em',
        },
        title: {
          'xs': 'font-size: 1.1em', 
          'sm': 'font-size: 1.3em', 
          'md': 'font-size: 1.4em', 
          'lg': 'font-size: 1.7em', 
          'xl': 'font-size: 1.9em',
        },
        description: {
          'xs': 'font-size: .8em', 
          'sm': 'font-size: .9em', 
          'md': 'font-size: 1.em', 
          'lg': 'font-size: 1.1em', 
          'xl': 'font-size: 1.2em',
        }
      },
      enseignes: {
        title: {
          'xs': 'font-size: 1.0em', 
          'sm': 'font-size: 1.1em', 
          'md': 'font-size: 1.1em', 
          'lg': 'font-size: 1.1em', 
          'xl': 'font-size: 1.1em',
        },
        description: {
          'xs': 'font-size: .9em', 
          'sm': 'font-size: .9em', 
          'md': 'font-size: .9em', 
          'lg': 'font-size: .9em', 
          'xl': 'font-size: .9em',
        },
        hover: {
          title: {
            'xs': 'font-size: 1.3em', 
            'sm': 'font-size: 1.5em', 
            'md': 'font-size: 1.7em', 
            'lg': 'font-size: 2.0em', 
            'xl': 'font-size: 2.4em',
          },
          description: {
            'xs': 'font-size: .9em', 
            'sm': 'font-size: .9em', 
            'md': 'font-size: .9em', 
            'lg': 'font-size: .9em', 
            'xl': 'font-size: 1.4em',
          }
        },
        
      },
      form: {
        card_title: {
          'xs': 'font-size: 1.0em', 
          'sm': 'font-size: 1.2em', 
          'md': 'font-size: 1.4em', 
          'lg': 'font-size: 1.6em', 
          'xl': 'font-size: 1.1em',
        },
      }
    }
  }
  
  // getters
  const getters = {}
  
  // actions
  const actions = {
  }
  
  // mutations
  const mutations = {
    increment_home_window (state) {
      state.home_window ++
    },
    decrement_home_window (state) {
      state.home_window --
    },
    reset_home_window (state) {
      state.home_window = 0;
    },
    set_hide_footer (state, value){
      state.hide_footer = value;
    },
    set_size (state, value){
      state.size = value;
    }
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }