<template>
  <v-container fluid class="text-center">
    <v-btn
      text
      large
      retain-focus-on-click
      :color="btn_color"
      @click="toggle"
      v-scroll-to="{
        el: '#homeview-markets',
        duration: 1500,
      }"
    >
      <v-row align="center" justify="center">
        <v-col
          cols="12"
          class="ma-0 pt-1 px-0 pb-0 text-center"
          :style="btn_size_text"
        >
          {{ btn_text }}
        </v-col>
        <v-col cols="12" class="mt-0 px-0 pt-0">
          <v-icon :color="btn_color" v-bind="btn_size">
            {{ btn_icon }}
          </v-icon>
        </v-col>
      </v-row>
    </v-btn>
  </v-container>
</template>

<script>
export default {
  name: "TheHomeButton",
  props: {
    down: Boolean,
    btn_color: String,
    btn_text: String,
    btn_icon: String,
  },
  methods: {
    toggle: function () {
      if (this.down == true) {
        this.$store.commit("parameters/increment_home_window");
      } else {
        this.$store.commit("parameters/decrement_home_window");
      }
    },
  },
  computed: {
    btn_size: function () {
      var temp =
        this.$store.state.parameters.btn_size[this.$vuetify.breakpoint.name];
      return { [temp]: true };
    },
    btn_size_text: function () {
      return this.$store.state.parameters.text_sizes.btn[
        this.$vuetify.breakpoint.name
      ];
    },
  },
};
</script>
