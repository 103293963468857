<template>
      <v-container fill-height class="bg-prlx" 
      id="thedescription">
      <TheTitle/>
      <TheHomeButton down btn_color="white" :btn_text="$t('thedescription.button')" btn_icon="fa-chevron-down"/>
      </v-container>
</template>

<script>
import TheTitle from './TheTitle.vue';
import TheHomeButton from './TheHomeButton';

export default {
  name: 'TheDescription',
  components: {
    TheTitle,
    TheHomeButton
  },

  data: () => ({
    innerheight: 1000,
    innerwidth: 1000,
    scrolled: false,
  }),
  mounted () {
        this.onResize()
  },
  methods: {
    onResize: function () {
        this.innerheight = window.innerHeight
        this.innerwidth = window.innerWidth
    }
  }

}
</script>


<style scoped>
@import "../scss/thedescription.scss";
</style>
