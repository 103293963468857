<template>
  <v-container v-if="!is_fetching" fluid id="themenu">
    <nav :style="{ color: `${textcolor}` }">
      <router-link
        to="/"
        :style="menu_size"
        class="site-title"
        v-if="
          this.scrollPosition > this.innerheight - this.innerheight / 10 ||
          !this.is_home_page
        "
      >
        <span
          v-html="site_title"
          style="
             {
              justify: center;
            }
          "
        ></span>
      </router-link>
      <ul v-if="screen_size != 'xs'">
        <router-link tag="li" to="/enseignes" :style="menu_size">{{
          $t("themenu.actors")
        }}</router-link>
        <router-link tag="li" to="/evenements" :style="menu_size">{{
          $t("themenu.markets")
        }}</router-link>
        <router-link tag="li" to="/contact" :style="menu_size">{{
          $t("themenu.contact")
        }}</router-link>
        <router-link
          tag="li"
          to="/exposer"
          :style="menu_size"
          class="exposer"
          >{{ $t("themenu.expose") }}</router-link
        >
      </ul>
    </nav>
  </v-container>
</template>

<script>
import config from "../../config/Config.js";
import Constants from "../constants";
import BackApi from "../functions/BackApi";

export default {
  name: "TheMenu",
  props: {
    scrollPosition: Number,
    innerheight: Number,
  },

  data: () => ({
    evenements_url:
      config.development.lemarche_website.url +
      "/" +
      config.development.lemarche_website.evenements,
    enseignes_url:
      config.development.lemarche_website.url +
      "/" +
      config.development.lemarche_website.enseignes,
    home_url: config.development.lemarche_website.url,
    app_text_color: { dark: "#FFFFFF", light: "#a9abae" },
    date_colors: { dark: "#83d5f2", light: "#357389" },
    logo_colors: { dark: "#3c93de", light: "#173753" },
    is_fetching: true,
  }),
  mounted() {
    BackApi.getNextEvent().then((response) => {
      this.nextEvent = response;
      this.is_fetching = false;
    });
  },
  computed: {
    is_home_page: function () {
      return this.$route.name == Constants.ROUTES.HOME;
    },
    screen_size: function () {
      return this.$vuetify.breakpoint.name;
    },
    menu_size: function () {
      return this.$store.state.parameters.text_sizes.menu[
        this.$vuetify.breakpoint.name
      ];
    },
    textcolor: function () {
      if (this.is_home_page) {
        if (this.scrollPosition > this.innerheight - this.innerheight / 10) {
          return this.app_text_color.light;
        } else {
          return this.app_text_color.dark;
        }
      } else {
        return this.app_text_color.light;
      }
    },
    datecolor: function () {
      if (this.is_home_page) {
        if (this.scrollPosition > this.innerheight - this.innerheight / 10) {
          return this.date_colors.light;
        } else {
          return this.date_colors.dark;
        }
      } else {
        return this.date_colors.light;
      }
    },
    logocolor: function () {
      if (this.is_home_page) {
        if (this.scrollPosition > this.innerheight - this.innerheight / 10) {
          return this.logo_colors.light;
        } else {
          return this.logo_colors.dark;
        }
      } else {
        return this.logo_colors.light;
      }
    },
    site_title: function () {
      var months = new Array(
        "Jan",
        "Fev",
        "Mar",
        "Avr",
        "Mai",
        "Jui",
        "Juil",
        "Aou",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      );
      var next_event = this.nextEvent;

      var date_start_day = "";
      var date_start_month = "";
      var date_end_day = "";
      var date_end_month = "";
      var date_end_year = "";

      var location = "";
      if (next_event) {
        var date_start = new Date(next_event.dateStart);
        date_start_day = date_start.getDate();
        date_start_month = months[date_start.getMonth()];

        var date_end = new Date(next_event.dateEnd);
        date_end_day = date_end.getDate();
        date_end_month = months[date_end.getMonth()];
        date_end_year = date_end.getFullYear();

        location = next_event.location.name.includes("Lyautey")
          ? "Place Lyautey, Lyon 6e"
          : next_event.location.name;
      }

      // Define title
      var titleName = this.$t("themenu.title");
      if (this.nextEvent.name) {
        titleName = this.nextEvent.name;
      }

      var title =
        "<div class='site_title' style='font-family: Pompiere;height:130%;font-size:140%;font-weight:500;text-transform:uppercase;color:" +
        this.logocolor +
        ";'>" +
        titleName +
        "</div>";
      if (date_start_day != "" && date_end_day != "") {
        if (date_start_month == date_end_month) {
          title +=
            "<div class='site_title' id='themenu-title' style='height:68%;font-size:95%;font-weight:450;color:" +
            this.datecolor +
            ";'>" +
            date_start_day +
            "." +
            date_end_day +
            " " +
            date_end_month +
            " " +
            date_end_year +
            "</div>";
        } else {
          title +=
            "<div class='site_title' id='themenu-title' style='height:68%;font-size:95%;font-weight:450;color:" +
            this.textcolor +
            ";'>" +
            date_start_day +
            " " +
            date_start_month +
            "." +
            date_end_day +
            " " +
            date_end_month +
            " " +
            date_end_year +
            "</div>";
        }
      }

      if (location) {
        title +=
          "<div class='site_title' id='themenu-title' style='height:100%;font-size:90%;font-weight:300;color:" +
          this.textcolor +
          ";'>" +
          location +
          "</div>";
      }
      return title;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/themenu.scss";
</style>
