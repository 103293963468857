const ROUTE_HOME = "home"
const ROUTE_ACTORS = "actors"

const MEDIA_FACEBOOK = "https://www.facebook.com/LeMarcheAutrement"
const MEDIA_INSTAGRAM = "https://www.instagram.com/le_marche_autrement_/"

const LOCALE_DEFAULT = "fr"
const LOCALE_FOLDER = "./locales"

export default {
    ROUTES: {
        HOME: ROUTE_HOME,
        ACTORS: ROUTE_ACTORS
    },
    MEDIA: {
        FACEBOOK: MEDIA_FACEBOOK,
        INSTAGRAM: MEDIA_INSTAGRAM
    },
    LOCALE: {
        DEFAULT: LOCALE_DEFAULT,
        FOLDER: LOCALE_FOLDER
    }
}