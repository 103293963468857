import axios from 'axios'

// initial state
const state = {
    management: [],
    management_list: []
  }
  
  // getters
  const getters = {
  }
  
  // actions
  const actions = {
  }
  
  // mutations
  const mutations = {

    async load_from_json(state){
      var management_json = await axios.get('./data/management.json').then(res => res.data)
      state.management = management_json.management
    },

    store_management_list (state, man) {
      state.management_list = man;
    }
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }