<template>
  <v-container id="thehalls" fluid>
    <h1 class="app-sub-title">
      {{ $t("thehalls.title") }}</h1>
    <v-item-group>
      <v-row justify="center" align="center">
        <v-col v-bind:key="halle.name" v-for="halle in halles" cols="12" sm="4">
          <v-hover v-slot:default="{ hover }">
            <AHallItem v-bind:halle="halle" v-bind:hover="hover" />
          </v-hover>
        </v-col>
      </v-row>
    </v-item-group>
  </v-container>
</template>

<script>
import AHallItem from "./AHallItem.vue";
import { development } from "../../config/Config";

export default {
  name: "TheHalls",

  components: {
    AHallItem,
  },
  data: () => ({
    halles: development.lemarche_website.map_halles,
  }),
};
</script>

<style lang="scss" scoped>
@import "../scss/thehalls.scss";
</style>
