<template>
  <v-card id="thebottombar" tile color="footer_color">
    <v-card-text id="thebottombar-card">
      <v-row justify="center">
        <v-col class="thebottombar-col" :cols="screen_size == 'xs' ? 12 : 6">
          <v-row justify="center">
            <v-col class="thebottombar-text">
              <router-link class="thebottombar-text" tag="a" to="/">
                {{ $t("themenu.home") }}
              </router-link>
              <br />
              <router-link class="thebottombar-text" tag="a" to="enseignes">{{
                $t("themenu.actors")
              }}</router-link>
              <br />
              <router-link class="thebottombar-text" tag="a" to="evenements">{{
                $t("themenu.markets")
              }}</router-link>
              <br />
              <router-link class="thebottombar-text" tag="a" to="contact">{{
                $t("themenu.contact")
              }}</router-link
              ><br />
            </v-col>
            <v-col>
              <router-link class="thebottombar-text" tag="a" to="exposer">{{
                $t("themenu.expose")
              }}</router-link
              ><br />
              <a class="thebottombar-text" href="https://icons8.com/">{{
                $t("thebottombar.nav.icons")
              }}</a
              ><br />
              <a
                class="thebottombar-text"
                onclick="tarteaucitron.userInterface.openPanel();"
                >{{ $t("thebottombar.nav.cookies") }}</a
              >
            </v-col>
            <v-spacer v-if="screen_size != 'xs'"></v-spacer>
          </v-row>
        </v-col>

        <v-col
          :cols="screen_size == 'xs' ? 12 : 6"
          class="thebottombar-col thebottombar-text"

          :style="screen_size == 'xs' ? {marginTop: '10px'}: {marginTop: '0'}"
        >
          <h1 class="thebottombar-title">
            {{ $t("thebottombar.newsletter.title") }}
          </h1>
          <br />
          {{ $t("thebottombar.newsletter.content") }}
          <iframe
            src="./newsletter.html"
            title="Abonnement newsletter"
            style="margin: 0; border: none; width: 100%; height: 50px"
          ></iframe>
        </v-col>
      </v-row>

      <v-row justify="center" class="ma-0a">
        <v-col class="thebottombar-icons" v-for="(icon, idx) in icons" :key="idx" :cols="cols_number">
          <v-btn icon link :href="icon.link" target="_blank" class="ma-0">
            <v-icon class="ma-0" :size="icon_size">{{ icon.name }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <br />
      <p class="thebottombar-text mb-0">
        {{ $t("thebottombar.legal.name") }}
        <router-link class="thebottombar-text" to="mentions-legales">{{
          $t("thebottombar.legal.mention")
        }}</router-link>
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import Constants from "../constants";

export default {
  name: "TheBottomBar",
  data: () => ({
    icons: [
      {
        name: "$vuetify.icons.facebookPastel",
        link: Constants.MEDIA.FACEBOOK,
      },
      {
        name: "$vuetify.icons.instagramPastel",
        link: Constants.MEDIA.INSTAGRAM,
      },
    ],
  }),
  computed: {
    icon_size: function () {
      switch (this.screen_size) {
        case "xs":
          return 30;
        case "sm":
          return 38;
        case "md":
          return 44;
        case "lg":
          return 50;
        case "xl":
          return 64;
        default:
          return 24;
      }
    },
    cols_number: function () {
      switch (this.screen_size) {
        case "xl":
          return 1;
        default:
          return 2;
      }
    },
    screen_size: function () {
    //   console.log(this.$vuetify.breakpoint.name);
      return this.$vuetify.breakpoint.name;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/thebottombar.scss";
</style>
