
import colors from 'vuetify/lib/util/colors'

export default {
    light: {
        primary: '#C48952', // #E53935
        secondary: '#357389', // #FFCDD2
        accent: '#357389',
        error: '#f44336',
        info: '#357389',
        success: '#70a451',
        warning: '#ed9419',

        // Colors
        custom_yellow: '#F3A83C',
        custom_blue: "#275f68",
        custom_brown: "#C48952", 

        // Global
        menu_title_color: '#F3A83C',
        menu_bg_color: '#FFFFFF',
        footer_color: '#275f68',
        footer_icon_color: '#F4F8F8',

        // HomePage
        home_floating_button_icon: '#F4F8F8',
        home_title: '#357389',
        home_halles_text: '#FFFFFF',

        // EvenementsPage
        evenement_item_title: '#FFFFFF',
        evenement_item_ribbon: '#F3A83C',
        evenement_item_icon: '#F3A83C',
        evenement_item_bouton: '#357389',
        evenement_item_bouton_text: '#F4F8F8',
        evenement_item_overlay_cross: '#357389',
        evenement_item_overlay_text: '#000000',

        // EnseignesPage
        enseigne_toolbar: '#357389',
        enseigne_select_halles: '#91BBC1',
        enseigne_select_marches: '#91BBC1',
        enseigne_item_card: '#FBFBFB',
        enseigne_item_title: '#000000',

        // FormPage
        global_boutton:'#357389',
        global_boutton_text: '#F4F8F8',
        global_icon: '#F3A83C',
        
        home_clear_boutton: '#C48952',
        home_alert_success: '#70a451',
        home_alert_error: colors.red.darken3,
        
        halle_artiste: '#F9E1E0',
        halle_artisans: '#2D3142',
        halle_createur: '#FEADB9',
        halle_gourmande: '#BC85A3',
        halle_atable: "#70AE98",
        halle_bienetre: '#9799BA',
        halle_decoration: '#BC85A3',
        halle_partenaire: '#00f0a4',
        halle_autres: '#D9EFFC'
    }
}