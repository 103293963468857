import Vue from 'vue'
import App from './App.vue'
import './scss/variables.scss'
import i18n from "./i18n";

import router from './router'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@fortawesome/fontawesome-free/css/all.css'
import 'animate.css'
import 'fullpage-vue/src/fullpage.css'
import VueFullpage from 'fullpage-vue'
import store from './store'
import themes from '../config/Themes.js'
import VueLogger from 'vuejs-logger';
import VueCookie from 'vue-cookie';
import config from '../config/Config.js';
import VueGtag from "vue-gtag";
import VueScrollTo from 'vue-scrollto';
import VueKinesis from 'vue-kinesis'
import { VuePrlxDirective, VuePrlx } from 'vue-prlx'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import AsyncComputed from 'vue-async-computed'
import { Icon } from 'leaflet';
import EnlargeableImage from '@diracleo/vue-enlargeable-image';
import Embed from 'v-video-embed';
import VideoBg from 'vue-videobg';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
Vue.use(AsyncComputed)
Vue.use(Embed);

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('video-bg', VideoBg);

Vue.use(VuePrlx);
Vue.directive('prlx', VuePrlxDirective); 
Vue.use(VueKinesis)

Vue.use('enlargeable-image',EnlargeableImage)


import AtableIcon from '@/components/icons/AtableIcon.vue'
import FacebookIcon from '@/components/icons/FacebookIcon.vue'
import InstagramIcon from '@/components/icons/InstagramIcon.vue'

const isProduction = config.env === 'production';
 
const options = {
    isEnabled: true,
    logLevel : isProduction ? 'error' : 'debug',
    stringifyArguments : false,
    showLogLevel : true,
    showMethodName : true,
    separator: '|',
    showConsoleColors: true
};

Vue.use(VueScrollTo)
Vue.use(VueGtag, {
  config: { 
    id: "UA-165730966-1",
    params: {
      send_page_view: false
    }
  }
}, router);
Vue.use(VueLogger, options);
Vue.use(VueFullpage)
Vue.use(Vuetify)
Vue.use(VueCookie);


export default new Vuetify({
  icons: {
    iconfont: 'fa',
    values: {
      atable: {
        component: AtableIcon,
      },
      facebookPastel: { 
        component: FacebookIcon,
      },
      instagramPastel: { 
        component: InstagramIcon, 
      }
    },
  },
  theme: {
    options: {
      customProperties: true,
    },
    dark: false,
    themes: themes
  }
})

Vue.config.productionTip = false

Vue.directive('scroll', {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})

Vue.mixin({
  methods: {
    urlify: function(text) {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      text = text.replace(urlRegex, function(url) {
          return '<a href="' + url + '" target="_blank">' + url + '</a>';
      })
      const urlRegexWww = /( |^)www\..*\.([a-z]){1,3}/g;
      text =  text.replace(urlRegexWww, function(url) {
          return '<a href="' + 'http://' + url.trim() + '" target="_blank">' + url + '</a>';
      })
      const mailRegex = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g;
      text =  text.replace(mailRegex, function(mail) {
          return '<a href="mailto:' + mail + '?">' + mail + '</a>';
      })
      return text;
    },
  },
})

new Vue({
  router,
  store,
  i18n,
  vuetify: new Vuetify(),
  components: { App },
  template: '<App/>',
  render: h => h(App),
}).$mount('#app')


