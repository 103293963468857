// initial state
const state = {
    token: String
  }
  
  // getters
  const getters = {
  }
  
  // actions
  const actions = {
  }
  
  // mutations
  const mutations = {
    store_token (state, token) {
      state.token = token
    },
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }