import Vue from 'vue'
import axios from 'axios'
import {development} from '../../../config/Config'


// initial state
const state = {
    enseignes: [],
    market_dispayed: ['ma12', 'ma11', 'ma10','ma9','ma8', 'ma7'],
  }
  
  // getters
  const getters = {
  }
  
  // actions
  const actions = {
  }
  
  // mutations
  const mutations = {
    store_enseignes (state, enseignes) {
      Vue.$log.debug(enseignes)
      state.enseignes = enseignes
    },

    async load_from_json(state){
      var enseignes_json = await axios.get('./data/enseignes.json').then(res => res.data)
      state.enseignes = enseignes_json.enseignes.filter( obj =>{
        for (var j = 0; j < state.market_dispayed.length; j ++){
          if (obj.marches.includes(state.market_dispayed[j])){
            return true
          }
        }
      return false;
      })
    },
    
    store_enseignes_v8 (state, enseignes) {
      state.enseignes = []

      for (var j = 0; j < enseignes.length; j ++){
        var temp_real_halle = Object
        var done = false
        if (enseignes[j]['halle_c'] === ""){
          temp_real_halle = development.lemarche_website.map_halles[development.lemarche_website.map_halles.length-1]
        }
        else{
          for (var i = 0; i < development.lemarche_website.map_halles.length; i ++) {
            for (var jj = 0; jj < development.lemarche_website.map_halles[i].value.length; jj ++) {
              if (enseignes[j]['halle_c'].toLowerCase() == development.lemarche_website.map_halles[i].value[jj].toLowerCase()){
                temp_real_halle = development.lemarche_website.map_halles[i]
                done = true
                break
              }
            }
            if (done){
              break
            }
          }
          if (!done){
            temp_real_halle = development.lemarche_website.map_halles[development.lemarche_website.map_halles.length-1]
          }
        }
        state.enseignes.push({
          id : enseignes[j]['id'],
          enseigne : enseignes[j]['name'].replace('&#039;', "'").replace("&IUML;", "ï"),
          halle : enseignes[j]['halle_c'],
          real_halle : temp_real_halle,
          website : enseignes[j]['website'],
          description : enseignes[j]['activite_c'].replace('&#039;', "'").replace("&IUML;", "ï"),
          prenom : enseignes[j]['last_name'],
          preference : enseignes[j]['contact_preference_c'],
          adresse : enseignes[j]['lead_source_description'],
          marche_solidaire : enseignes[j]['marche_solidaire_c'],
          livraison : enseignes[j]['livraison_c'],
          promouvoir : enseignes[j]['promouvoir_c'],
          marches : []
        })
      }
      Vue.$log.debug(state.enseignes)
    },

    add_evenements (state, evenements) {
      for (var j = 0; j < evenements.evenements.length; j ++){
        for (var i = 0; i < state.enseignes.length; i++){
          if (!("marches" in state.enseignes[i])){
            state.enseignes[i]["marches"] = []
          }

          if ( state.enseignes[i].id in evenements.evenements[i].list_enseignes){
            state.enseignes[i]["marches"].push("ma" + evenements.evenements[i].number)
          }
        }
      }
      Vue.$log.debug(state.enseignes)
    },

    add_evenements_v8 (state, organisations) {
      for (var j = 0; j < organisations.organisations.length; j ++){
        for (var i = 0; i < state.enseignes.length; i++){
          
          if (organisations.organisations[j].enseignes.name.toLowerCase() == state.enseignes[i].enseigne.toLowerCase()){
            if (!("marches" in state.enseignes[i])){
              state.enseignes[i]["marches"] = []
            }
            var market = "ma" + organisations.organisations[j].marche.name.split("#")[1]
            if(!(market in state.enseignes[i]['marches'])){
              state.enseignes[i]["marches"].push(market)
            }
          }
        }
      }
      Vue.$log.debug(state.enseignes) 
    },

    add_email_v8 (state, enseigneId, email){
      for (var j = 0; j < state.enseignes.length; j++){
        if (state.enseignes[j].id == enseigneId){
          state.enseignes[j]['email'] = email
          break
        }
      }
    }
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }