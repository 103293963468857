import { render, staticRenderFns } from "./ThePartner.vue?vue&type=template&id=3e8a7aa6&scoped=true"
import script from "./ThePartner.vue?vue&type=script&lang=js"
export * from "./ThePartner.vue?vue&type=script&lang=js"
import style0 from "./ThePartner.vue?vue&type=style&index=0&id=3e8a7aa6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e8a7aa6",
  null
  
)

export default component.exports