module.exports = {
  env: "development",
  development: {
    suitecrm: {
      url: "http://localhost:3000",
      api_v8_url: "https://crm.lemarcheautrement.fr/suitecrm/Api/index.php",
      api_v8_routes: {
        header: {
          Authorization: "Bearer ",
        },
        access_token: {
          route: "access_token",
          headers: {
            "Content-type": "application/vnd.api+json",
            Accept: "application/vnd.api+json",
          },
          params: {
            grant_type: "client_credentials",
            client_id: "2b9eeb51-645a-5857-5b6c-5e918a1fd967",
            client_secret: "0AveW0esJYNrzV5p18Ub",
          },
        },
        enseignes: {
          route: "V8/module/Leads",
          params: {
            "page[size]": 2000,
            "page[number]": 1,
            "fields[Leads]":
              "id,name,halle_c,website,last_name,phone_mobile,contact_preference_c,lead_source_description,activite_c,email1,marche_solidaire_c,livraison_c,promouvoir_c",
            sort: "name",
          },
        },
        emails: {
          route: "V8/module/EmailAddresses",
          params: {
            "fields[EmailAddresses]": "id,email_address,invalid_email",
          },
        },
        relationEmail: {
          route: "V8/module/Leads/enseigneId/relationships/email_addresses",
        },
        evenements: {
          route: "V8/module/FP_events",
          params: {
            "page[size]": 2000,
            "page[number]": 1,
            "fields[FP_events]":
              "id,name,description,duration,date_start,date_end,name,fp_event_locations_fp_events_1_name,fp_event_locations_fp_events_1fp_event_locations_ida,description_2_c",
            "filter[deleted][eq]": 0,
            sort: "date_start",
          },
        },
        users: {
          route: "V8/module/Users",
          params: {
            "page[size]": 2000,
            "page[number]": 1,
            "fields[Users]":
              "id,full_name,first_name,last_name,title,phone_mobile,phone_work,email1,photo,description",
            "filter[is_admin][eq]": false,
            "filter[operator]": "and",
            "filter[user_name][neq]": "suitecrm_reader",
          },
        },
        organisations: {
          route: "V8/module/AOS_Quotes",
          params: {
            "page[size]": 2000,
            "page[number]": 1,
            "fields[AOS_Quotes]":
              "id,name,fp_events_aos_quotes_1_name,fp_events_aos_quotes_1fp_events_ida,leads_aos_quotes_1_name,leads_aos_quotes_1leads_ida",
            "filter[deleted][eq]": 0,
            sort: "leads_aos_quotes_1_name",
          },
        },
        lieux: {
          route: "V8/module/FP_Event_Locations",
          params: {
            "page[size]": 2000,
            "page[number]": 1,
          },
        },
      },
    },
    lemarche_website: {
      url: "http://localhost:8080/#",
      evenements: "evenements",
      enseignes: "evenements",
      baseline: [
        "une véritable halle à ciel ouvert",
        "le marché qui bouscule les tendances et les traditions",
      ],
      image_url: [
        "index.php?entryPoint=download&id=",
        "&type=SugarFieldImage&isTempFile=1",
      ],
      image_server_path: "/var/lib/suitecrm/suitecrm/public/",
      memnbership_form_url: [
        "index.php?preview=yes&entryPoint=download&id=",
        "&type=ORGA_Bulletin",
      ],
      map_halles: [
        {
          name: "Artistes",
          value: ["Artiste", "Artistes"],
          color: "halle_artiste",
          img: "ARTISTE_4.jpg",
          search: "artiste",
        },
        {
          name: "Artisans",
          value: ["Artisans"],
          color: "halle_artisans",
          img: "ARTISAN.jpg",
          search: "artisans",
        },
        {
          name: "Créateurs",
          value: ["Créateur", "Createur", "Créateurs"],
          color: "halle_createur",
          img: "CREATEUR_1.jpg",
          search: "createur",
        },
        {
          name: "Epicerie",
          value: ["Epicerie"],
          color: "halle_gourmande",
          img: "EPICERIE_4.jpg",
          search: "epicerie",
        },
        {
          name: "A table !",
          value: ["Restauration"],
          color: "halle_atable",
          img: "A_TABLE_6.jpg",
          search: "restauration",
        },
        {
          name: "Producteurs",
          value: ["Producteurs"],
          color: "halle_bienetre",
          img: "PRODUCTEURS_ET_CONCEPTS_2.png",
          search: "producteur",
        },
        {
          name: "Concepts",
          value: ["Services"],
          color: "halle_services",
          img: "services.jpeg",
          search: "services",
        },
        {
          name: "Déco",
          value: ["Deco", "Déco", "Decoration", "Décoration"],
          color: "halle_decoration",
          img: "DECO_2.jpg",
          search: "deco",
        },
        {
          name: "Divers",
          value: ["Autre", "Autres", "Divers"],
          color: "halle_autres",
          img: "autres.jpeg",
          search: "autre",
        },
        {
          name: "Mode Vintage",
          value: ["Mode Vintage", "mode vintage"],
          color: "halle_autres",
          img: "autres.jpeg",
          search: "mode vintage",
        },
        {
          name: "Textile",
          value: ["Textile", "textile"],
          color: "halle_autres",
          img: "autres.jpeg",
          search: "mode vintage",
        },
      ],
      icons_halles: {
        Artistes: "icons8-paint-palette",
        Artisans: "icons8-paint-palette",
        Créateurs: "icons8-tailors-dummy",
        Epicerie: "icons8-kiosk-on-wheels",
        "A table !": "icons8-restaurant",
        Producteurs: "icons8-vegetables-box",
        Services: "icons8-chair-50",
        Déco: "icons8-cactus",
        Partenaires: "icons8-holding-hands",
        Divers: "icons8-abc",
      },
      paternaires_logos: [
        { img: "fede6.jpg", size: "50%", col: 3, sm: 2, lg: 1 },
        { img: "lyon_6.png", size: "80%", col: 3, sm: 2, lg: 1 },
        { img: "ieb.png", size: "65%", col: 3, sm: 2, lg: 1 },
        { img: "granvillage.png", size: "80%", col: 3, sm: 2, lg: 1 },
        { img: "ara.jpg", size: "80%", col: 4, sm: 3, lg: 2 },
        { img: "rfm.jpg", size: "50%", col: 3, sm: 2, lg: 1 },
      ],
    },
    backapi: {
      url: "http://localhost:8090/api/v1/",
      actors: "actors",
      managements: "managements",
      events: "events",
      users: "users",
      products: "products",
      membershipForm: "membershipForm",
    },
  },
};
