<template>
  <v-navigation-drawer
    v-model="drawer_prop"
    hide-overlay
    right
    app
    class="d-flex align-item-end text-center text-uppercase font-weight-bold"
    id="thenavbar"
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>
          <router-link tag="li" to="/enseignes" exact :style="menu_size">{{
            $t("themenu.actors")
          }}</router-link>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>
          <router-link tag="li" to="/evenements" exact :style="menu_size">{{
            $t("themenu.markets")
          }}</router-link>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>
          <router-link tag="li" to="/contact" :style="menu_size">{{
            $t("themenu.contact")
          }}</router-link>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item class="px-0">
      <v-list-item-content>
        <v-list-item-title>
          <router-link tag="li" to="/exposer" :style="menu_size" id="exposer">{{
            $t("themenu.expose")
          }}</router-link>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-navigation-drawer>
</template>
<script>
export default {
  name: "TheNavBar",
  props: {
    drawer: Boolean,
  },
  data: () => ({
    drawer_prop: false,
  }),
  watch: {
    drawer: function () {
      this.drawer_prop = !this.drawer_prop;
    },
  },
  computed: {
    menu_size: function () {
      return this.$store.state.parameters.text_sizes.menu[
        this.$vuetify.breakpoint.name
      ];
    },
  },
};
</script>

<style scoped>
@import "../scss/thenavbar.scss";
</style>
