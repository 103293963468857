<template>
  <v-container id="thevideo-container" fluid >
    <video
      class="video"
      id="thevideo"
      controls
      :src="`${video}`"
      type="video/mp4"
      autoplay
      playinline
      loop
      muted
    ></video>
  </v-container>
</template>

<script>
export default {
  name: "TheVideo",
  data: () => ({
    video: "/assets/video.mp4",
  }),
};
</script>

<style scoped>
@import "../scss/thevideo.scss";
</style>
