<template>
  <svg @click="rotate"
  xmlns="http://www.w3.org/2000/svg" 
  id="Outline" 
  viewBox="0 0 512 512" 
  width="512" height="512">
    <path ref="test" d="M392,48a104.462,104.462,0,0,0-42.239,8.947,127.885,127.885,0,0,0-187.579,0A104.006,104.006,0,1,0,93.621,252.623L120,408.672V488a8,8,0,0,0,8,8H384a8,8,0,0,0,8-8V408.672l26.379-156.049A104.014,104.014,0,0,0,392,48ZM376,480H136V416H376Zm33.771-241.794a8,8,0,0,0-6.281,6.5L377.239,400H328.592l7.387-103.43h-.008c.014-.189.029-.377.029-.569a8,8,0,0,0-15.971-.569h-.008L312.551,400H264V312a8,8,0,0,0-16,0v88H199.449l-7.47-104.57h-.008A8,8,0,0,0,176,296c0,.192.015.38.029.569h-.008L183.408,400H134.761L108.51,244.709a8,8,0,0,0-6.281-6.5A88.009,88.009,0,1,1,208,152a8,8,0,0,0,16,0,103.994,103.994,0,0,0-47.262-87.116,112.016,112.016,0,0,1,187.237,49.244,8,8,0,1,0,15.422-4.256,126.93,126.93,0,0,0-19.08-39.994A87.115,87.115,0,0,1,392,64a88.01,88.01,0,0,1,17.771,174.206Z"/>
  </svg>
</template>
<script>
import { TweenMax, Sine } from 'gsap'

export default {
    name: "AtableIcon",

    methods: {
        rotate: function(){
            TweenMax.to(this.$refs.test, 0.25, {
                rotation: 30,
                repeat: 3,
                yoyo: true,
                svgOrigin: '250 250',
                ease: Sine.easeInOut
            })
        }
    }
}
</script>