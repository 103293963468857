import Vue from 'vue'
import axios from 'axios'

// initial state
const state = {
    users: []
  }
  
  // getters
  const getters = {
  }
  
  // actions
  const actions = {
  }
  
  // mutations
  const mutations = {

    async load_from_json(state){
      var users_json = await axios.get('./data/users.json').then(res => res.data)
      state.users = users_json.users
    },

    store_users (state, users) {
      Vue.$log.debug(users)
      state.users = users
    },

    store_users_v8 (state, users) {
      state.users = []

      for (var j = 0; j < users.length; j ++){
        state.users.push({
          id : users[j]['id'],
          full_name : users[j]['full_name'],
          first_name : users[j]['first_name'],
          last_name : users[j]['last_name'],
          title : users[j]['title'],
          phone_mobile : users[j]['phone_mobile'],
          phone_work : users[j]['phone_work'],
          email1 : users[j]['email1'],
          photo : users[j]['photo'],
          description : users[j]['description'],
        })
      }
      Vue.$log.debug(state.users)
    },
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }