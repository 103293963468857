import Vue from 'vue'
import axios from 'axios'


// initial state
const state = {
    organisations: []
  }
  
  // getters
  const getters = {
  }
  
  // actions
  const actions = {
  }
  
  // mutations
  const mutations = {
    store_organisations (state, organisations) {
      Vue.$log.debug(organisations)
      state.organisations = organisations.organisations
    },
    
    async load_from_json(state){
      var organisations_json = await axios.get('./data/organisations.json').then(res => res.data)
      state.organisations = organisations_json
    },

    store_organisations_v8 (state, organisations) {
      state.organisations = []

      for (var j = 0; j < organisations.length; j ++){
        state.organisations.push({
          id : organisations[j]['id'],
          name : organisations[j]['name'],
          marche : {
            id : organisations[j]['fp_events_aos_quotes_1fp_events_ida'],
            name : organisations[j]['fp_events_aos_quotes_1_name']
          },
          enseignes : {
            id : organisations[j]['leads_aos_quotes_1leads_ida'],
            name : organisations[j]['leads_aos_quotes_1_name']
          }
        })

        if (state.organisations[j].enseignes.name === ""){
          state.organisations[j].enseignes.name = organisations[j]['name'].split("-")[1]
        }
      }
      Vue.$log.debug(state.organisations)
    },


  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }