import Vue from 'vue'
import Vuex from 'vuex'
import parameters from './modules/parameters'
import evenements from './modules/evenements'
import enseignes from './modules/enseignes'
import token from './modules/token'
import users from './modules/users'
import management from './modules/management'
import organisations from './modules/organisations'
import lieux from './modules/lieux'
import produits from './modules/produits'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        parameters,
        evenements,
        enseignes,
        token,
        users,
        organisations,
        lieux,
        management,
        produits
    }
})